<template>
  <div>
    <sdPageHeader title="Profile Settings"/>
    <Main>
      <a-row :gutter="25">
        <a-col :xxl="5" :lg="7" :md="9" :xs="24">
          <ProfileAuthorBox>
            <author-box />
          </ProfileAuthorBox>
        </a-col>
        <a-col :xxl="19" :lg="17" :md="15" :xs="24">
          <SettingWrapper>
            <Suspense>
              <template #default>
                <router-view></router-view>
              </template>
              <template #fallback>
                <div class="spin">
                  <a-spin />
                </div>
              </template>
            </Suspense>
          </SettingWrapper>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import { Main } from '../../styled';
import { SettingWrapper } from './overview/style';
import AuthorBox from './overview/ProfileAuthorBox';
import { ProfileAuthorBox } from './overview/style';

export default {
  name: 'Settings',
  components: {
    Main,
    AuthorBox,
    SettingWrapper,
    ProfileAuthorBox,
  },
  data() {
    return {};
  },
};
</script>
